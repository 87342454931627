<script setup>
import { useRoute, useRuntimeConfig, useHead, useSeoMeta } from '#app'
import { buildUrl } from 'cloudinary-build-url'
import { useI18n } from '#imports'
import { ref, watch, computed, onMounted } from 'vue'
import { useMenu, useData } from 'refresh-common'

import { ListBlock, Section, Container, Loader, Title } from 'refresh-ui'

import SearchForm from '@/components/molecules/SearchForm.vue'
import TheComponents from '@/components/TheComponents.vue'

const props = defineProps({
  drupalData: Object,
})

const route = useRoute()
const { t } = useI18n()
const { closeMenuMain } = useMenu()

const config = useRuntimeConfig()

const textInput = ref(route?.query?.text)
const isLoading = ref(false)

const searchIndex = computed(() => {
  switch (config.public.siteLang) {
    case 'en':
      return config.public.searchAlgoliaIndexEn
    case 'sv':
      return config.public.searchAlgoliaIndexSv
    case 'uk':
      return config.public.searchAlgoliaIndexUk
    default:
      return config.public.searchAlgoliaIndexSv
  }
})

// eslint-disable-next-line no-undef
const { result, search } = useAlgoliaSearch(searchIndex.value)

const searchUrl = computed(() => {
  switch (config.public.siteLang) {
    case 'en':
      return config.public.searchEn
    case 'uk':
      return config.public.searchUk
    case 'sv':
      return config.public.searchSv
    default:
      return config.public.searchSv
  }
})

// console.log('searchurl', searchUrl.value)

// const { data: drupalRes } = await useAsyncData('drupalRes', () =>
//   useGetResource({ path: searchUrl.value }, t),
// )

const sections = useData(
  props.drupalData.data,
  {
    cmsBasePath: config.public.cmsBasePath,
    cloudname: config.public.cloudinaryCloudName,
  },
  t,
)

// console.log('sections', sections)

useHead({
  htmlAttrs: {
    lang: config.public.siteLang,
  },
})

const titleValue = computed(
  () =>
    props.drupalData?.data?.data?.attributes?.metatag?.find(
      element => element.attributes.name === 'title',
    )?.attributes?.content || '',
)

const descriptionValue = computed(
  () =>
    props.drupalData?.data?.data?.attributes?.metatag?.find(
      element => element.attributes.name === 'description',
    )?.attributes?.content || '',
)

const abstract = computed(
  () =>
    props.drupalData?.data?.data?.attributes?.metatag?.find(
      element => element?.attributes?.name === 'abstract',
    )?.attributes?.content ||
    descriptionValue.value ||
    '',
)

const keywords = computed(
  () =>
    props.drupalData?.data?.data?.attributes?.metatag?.find(
      element => element?.attributes?.name === 'keywords',
    )?.attributes?.content || '',
)

useSeoMeta({
  title: () => titleValue.value + ' | ' + config.public.siteName,
  description: () => descriptionValue.value,
  lang: () => config.public.siteLang,
  abstract: () => abstract.value,
  keywords: () => keywords.value,
})

const getImage = item => {
  if (item.computed_cloudinary_page) {
    return {
      url: buildUrl(item.computed_cloudinary_page, {
        cloud: {
          cloudName: config.public.cloudname,
        },

        transformations: {
          format: 'webp',
          resize: {
            type: 'fill',
            height: 1024,
          },
        },
      }),
      alt: item.title,
    }
  }
  if (item.uri) {
    return { url: config.public.cmsBasePath + item.uri, alt: item.alt }
  }
  return undefined
}

const getDataElement = items => {
  try {
    if (!items) {
      return { cards: [] }
    }
    return {
      cards: items.map(item => ({
        title: item.title,
        copy: item?.field_wysiwyg,
        link: { url: item?.url, title: t('read-more') },
        image: getImage(item),
      })),
    }
  } catch (error) {
    console.warn('search page', error)
  }
}

const searchFn = async () => {
  isLoading.value = true
  await search({ query: route?.query?.text })
  textInput.value = route?.query?.text
  isLoading.value = false
}

onMounted(async () => {
  closeMenuMain()
  if (!route?.query?.text) {
    //removed because it's not needed
    // await search({
    //   query: 'empty',
    // });
    return
  }
  await search({
    query: route?.query?.text,
  })
})

watch(
  () => result.value,
  () => closeMenuMain(),
)

watch(
  () => route.query.text,
  () => searchFn(),
)
</script>

<template>
  <ClientOnly>
    <SearchForm :is-loading="isLoading" :text-input="textInput" />
    <Container v-if="isLoading" class="mx-auto max-w-2xl py-10 lg:py-20">
      <Loader />
    </Container>

    <div v-if="result?.hits.length > 0" class="flex w-full flex-col items-center py-10 lg:py-20">
      <div class="mb-4 flex flex-col items-center">
        <Title>{{ $t('search-result') }}</Title>
        <div>{{ result?.hits.length }} {{ $t('results') }}</div>
      </div>

      <ListBlock
        v-if="!isLoading"
        :data="getDataElement(result?.hits)"
        class="w-full max-w-[1920px]"
      />
    </div>

    <Container v-else-if="route?.query?.text && !isLoading">
      <div class="mx-auto max-w-2xl py-10 lg:py-20">
        <Title custom-style="mb-6 !text-2xl lg:!text-3xl">
          {{ $t('searchNotFound.title') }}
        </Title>

        <ul class="custom-list space-y-5 font-sans text-base text-black lg:text-xl">
          <li>{{ $t('searchNotFound.row1') }}</li>
          <li>
            {{ $t('searchNotFound.row2') }}
          </li>
          <li>
            {{ $t('searchNotFound.row3') }}
          </li>
        </ul>
      </div>
    </Container>
  </ClientOnly>

  <template v-for="section in sections" :key="section.id">
    <Section v-if="section.children" :background="section.background" :space="section.space">
      <TheComponents v-if="section.children" :components="section.children" />
    </Section>
  </template>
</template>
