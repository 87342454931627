<script setup>
// TODO: Type this
import { computed, onMounted, ref } from 'vue'
import { buildUrl } from 'cloudinary-build-url'
import { useRoute, useRuntimeConfig, useAsyncData, useHead, useSeoMeta } from '#imports'

import { withQuery } from 'ufo'
import { useI18n } from '#imports'
const { t } = useI18n()
import { Spotify } from 'refresh-ui'
import { useGetResource } from '@/composables/useJsonApi'
import NodeBlog from '@/components/NodeBlog.vue'
import NodePage from '@/components/NodePage.vue'
import TheBreadcrumbs from '@/components/elements/TheBreadcrumbs.vue'
import PageLoader from '@/components/atoms/PageLoader.vue'
import { createError } from '#app'
import PageSearch from '~/components/PageSearch.vue'

const route = useRoute()

const config = useRuntimeConfig()

const showDashboard = ref(false)

const defaultImage =
  'https://res.cloudinary.com/klandestino-ab/image/upload/v1676532605/djurensratt-se/loggor/share_1200x630.png'

// TODO: Don't lazy load the main content -- it should very likely be loaded immediately
const {
  data: drupalRes,
  status,
  error,
} = await useAsyncData('drupalRes', async () => {
  return await useGetResource({
    query: route.query,
    params: route.params,
    hash: route.hash,
    path: route.path,
  })
})

if (error?.value?.statusCode === 404)
  throw createError({
    statusCode: 404,
    fatal: true,
  })

const normalizeUrlEncoding = url => {
  if (url.includes('%25') || url.includes('%3F') || url.includes('%3D')) {
    return decodeURIComponent(url) // Decode url if it contains encoded characters like %25 (%),  %3F (?), %3D (=)
  }
  return url
}

/**
 * If cloudinary allows unsigned access, we're good to remove the signature
 */
const removeCloudinarySignature = url => {
  return url.replace(/\/s--[^-]+--\//, '/')
}

const nodeId = computed(() => drupalRes?.value?.data?.data?.attributes?.drupal_internal__nid || 0)

const editUrl = computed(() => {
  return nodeId?.value
    ? withQuery(`${config.public.cmsBasePath}/node/${nodeId.value}/edit`, {
        dr_redirect: canonicalValue.value,
      })
    : ''
})

const contentUrl = computed(() => {
  return `${config.public.cmsBasePath}/admin/content`
})

const clearCacheUrl = computed(() => {
  return withQuery(canonicalValue.value, {
    cacheFlush: true,
  })
})

const previewAndCacheBurst = () => {
  navigateTo(
    withQuery(route.fullPath, {
      preview: true,
      cacheBurster: `${Date.now()}--${Math.floor(Math.random() * (9999999 - 1000000 + 1))}`,
    }),
    { external: true },
  )
}

const titleValue = computed(
  () =>
    drupalRes?.value?.data?.data?.attributes?.metatag?.find(
      element => element.attributes.name === 'title',
    )?.attributes?.content || '',
)

const descriptionValue = computed(
  () =>
    drupalRes?.value?.data?.data?.attributes?.metatag?.find(
      element => element.attributes.name === 'description',
    )?.attributes?.content ||
    //old news
    drupalRes?.value?.data?.data?.attributes.field_ingress?.value ||
    '',
)

const rawImage = computed(
  () =>
    drupalRes?.value?.data?.data?.attributes?.metatag?.find(
      element => element.attributes.rel === 'image_src',
    )?.attributes?.href || defaultImage,
)

const shareImage = computed(() => {
  const imageUrl = buildUrl(rawImage.value, {
    cloud: {
      cloudName: config.public.cloudname,
    },
    transformations: {
      resize: {
        type: 'fill',
        width: 1200,
        height: 630,
      },
      format: 'jpg',
    },
  })
  const normalizedUrl = normalizeUrlEncoding(imageUrl)
  const url = new URL(normalizedUrl)
  url.pathname += '.jpg'
  return removeCloudinarySignature(url.toString())
})

// TODO: Fix this hack. We are getting the jsonapi url from the canonical url
const canonicalValue = computed(
  () =>
    /* drupalRes?.value?.data?.data?.attributes?.metatag?.find(
      element => element.attributes.rel === 'canonical',
    )?.attributes?.href || '',*/
    config.public.siteUrl + route.fullPath,
)

// console.log('canonical', canonicalValue.value);

const abstract = computed(
  () =>
    drupalRes?.value?.data?.data?.attributes?.metatag?.find(
      element => element?.attributes?.name === 'abstract',
    )?.attributes?.content ||
    descriptionValue.value ||
    '',
)

const ogTitle = computed(
  () =>
    drupalRes?.value?.data?.data?.attributes?.metatag?.find(
      element => element?.attributes?.property === 'og:title',
    )?.attributes?.content ||
    titleValue.value ||
    '',
)

/*const ogImage = computed(
  () =>
    drupalRes?.value?.data?.data?.attributes?.metatag?.find(
      element => element?.attributes?.property === 'og:image',
    )?.attributes?.content ||
    shareImage.value || defaultImage,
)*/

const ogDescription = computed(
  () =>
    drupalRes?.value?.data?.data?.attributes?.metatag?.find(
      element => element?.attributes?.property === 'og:description',
    )?.attributes?.content ||
    descriptionValue.value ||
    '',
)

const ogType = computed(() => (drupalRes.value?.type === 'blog' ? 'article' : 'website'))

const twitterTitle = computed(
  () =>
    drupalRes?.value?.data?.data?.attributes?.metatag?.find(
      element => element?.attributes?.name === 'twitter:title',
    )?.attributes?.content ||
    titleValue.value ||
    '',
)

const twitterDescription = computed(
  () =>
    drupalRes?.value?.data?.data?.attributes?.metatag?.find(
      element => element?.attributes?.name === 'twitter:description',
    )?.attributes?.content ||
    descriptionValue.value ||
    '',
)

const twitterUrl = computed(
  () =>
    /* drupalRes?.value?.data?.data?.attributes?.metatag?.find(
      element => element?.attributes?.name === 'twitter:url',
    )?.attributes?.content ||
    canonicalValue.value ||
    '', */
    canonicalValue.value,
)

const twitterSite = computed(
  () =>
    drupalRes?.value?.data?.data?.attributes?.metatag?.find(
      element => element?.attributes?.name === 'twitter:site',
    )?.attributes?.content || '',
)

const robots = computed(
  () =>
    drupalRes?.value?.data?.data?.attributes?.metatag?.find(
      element => element?.attributes?.name === 'robots',
    )?.attributes?.content || 'index, follow',
)

useHead(() => ({
  htmlAttrs: {
    lang: config.public.siteLang,
  },
  link: [
    {
      rel: 'canonical',
      href: canonicalValue.value,
    },
  ],
}))

useSeoMeta({
  title: () => titleValue.value + ' | ' + config.public.siteName,
  description: () => descriptionValue.value,
  canonical: () => canonicalValue.value,
  lang: () => config.public.siteLang,
  ogImage: () => shareImage.value,
  ogUrl: () => canonicalValue.value,
  twitterCard: 'summary_large_image',
  twitterImage: () => shareImage.value,
  abstract: () => abstract.value,
  ogTitle: () => ogTitle.value,
  ogDescription: () => ogDescription.value,
  ogType: () => ogType.value,
  twitterTitle: twitterTitle.value,
  twitterDescription: () => twitterDescription.value,
  twitterUrl: () => twitterUrl.value,
  twitterSite: () => twitterSite.value,
  robots: () => robots.value,
})

// console.log('meta', {
//   title: titleValue.value,
//   description: descriptionValue.value,
//   canonical: canonicalValue.value,
//   lang: config.public.siteLang,
//   ogImage: ogImage.value,
//   ogUrl: canonicalValue.value,
//   twitterCard: 'summary_large_image',
//   twitterImage: ogImage.value,
//   abstract: abstract.value,
//   ogTitle: ogTitle.value,
//   ogDescription: ogDescription.value,
//   ogType: ogType.value,
//   twitterTitle: twitterTitle.value,
//   twitterDescription: twitterDescription.value,
//   twitterUrl: twitterUrl.value,
//   twitterSite: twitterSite.value,
//   robots: robots.value,
// });

onMounted(() => {
  const authCookie = useCookie('skey')
  showDashboard.value = Boolean(authCookie?.value)
})

const searchNodeIds = {
  en: 11297,
  sv: 10495,
  // uk: 12345, // add later
}

const isSearchPage = computed(() => nodeId.value === searchNodeIds[config.public.siteLang])
</script>

<template>
  <PageLoader :is-active="status === 'pending'" />

  <div
    v-if="showDashboard"
    class="fixed right-2 top-1/2 z-50 hidden -translate-y-1/2 transform flex-col gap-2 text-center font-sans text-base font-bold text-black lg:flex"
  >
    <a
      :href="editUrl"
      class="rounded-lg bg-white px-4 py-2 opacity-30 transition hover:bg-blue-dark hover:text-white hover:opacity-100"
      :title="t('admin.edit.hover')"
    >
      {{ $t('admin.edit') }}
    </a>
    <a
      :title="t('admin.preview.hover')"
      class="cursor-pointer rounded-lg bg-white px-4 py-2 opacity-30 transition hover:bg-blue-dark hover:text-white hover:opacity-100"
      @click="previewAndCacheBurst"
    >
      {{ $t('admin.preview') }}
    </a>
    <a
      :href="contentUrl"
      class="rounded-lg bg-white px-4 py-2 opacity-30 transition hover:bg-blue-dark hover:text-white hover:opacity-100"
      :title="t('admin.content.hover')"
    >
      {{ $t('admin.content') }}
    </a>
    <a
      :href="clearCacheUrl"
      class="rounded-lg bg-white px-4 py-2 opacity-30 transition hover:bg-blue-dark hover:text-white hover:opacity-100"
      :title="t('admin.clearCache.hover')"
    >
      {{ $t('admin.clearCache') }}
    </a>
  </div>

  <template v-if="status !== 'pending'">
    <TheBreadcrumbs
      v-if="drupalRes?.data?.data?.attributes?.breadcrumbs"
      :data="drupalRes?.data.data.attributes.breadcrumbs"
    />

    <PageSearch v-if="isSearchPage" :drupal-data="drupalRes" />
    <NodeBlog v-else-if="drupalRes?.type === 'blog'" :resource="drupalRes?.data" />
    <NodePage v-else :resource="drupalRes?.data" />
  </template>
  <Spotify />
</template>
