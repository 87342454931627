<template>
  <div class="flex justify-center bg-blue-lighter py-11">
    <div class="container mx-auto w-full max-w-3xl px-4">
      <Title custom-style="mb-9">{{ $t('what-are-you-looking-for') }}</Title>
      <div class="relative flex flex-1 flex-col md:flex-row md:items-end">
        <input
          v-model="text"
          class="border-px peer mb-4 max-h-[42px] min-w-[100px] flex-1 rounded-md border-blue-light p-2.5 pl-12 leading-none transition-colors duration-300 placeholder:text-grey focus:border-blue focus:border-transparent focus:ring-0 focus:placeholder:text-blue md:mb-0 md:rounded-r-none md:border-r-0 lg:max-h-[59px] lg:p-5 lg:pl-14 lg:text-lg lg:leading-none"
          type="text"
          :placeholder="$t('search')"
          @keydown.enter="handleSearch"
        />
        <SearchIcon
          class="absolute left-5 top-[11px] h-5 w-5 text-blue transition-colors duration-300 lg:top-[16px] lg:h-auto lg:w-auto"
        />
        <Button
          :title="$t('search')"
          class-name-text="text-base !leading-snug lg:leading-snug lg:text-xl"
          class="rounded-md !border-0 !py-2.5 px-8 md:!rounded-l-none lg:flex lg:rounded-md lg:!py-4 lg:px-10"
          :is-loading="isLoading"
          @click="handleSearch"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from '#app'
import { ref, watch } from 'vue'
import { Button, Title } from 'refresh-ui'
import SearchIcon from '@/components/atoms/Icons/Search.vue'
import { useSearchPath } from '@/composables/useSearchPath'

const router = useRouter()

const props = defineProps({
  textInput: String,
  isLoading: Boolean,
})

const text = ref(props.textInput)

const searchPath = useSearchPath()

const handleSearch = () => {
  if (!text.value) return
  if (text.value === props.textInput) return
  router.push({ path: searchPath, query: { text: text.value } })
}

watch(
  () => props.textInput,
  () => (text.value = props.textInput),
)
</script>
